import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import * as screenfull from 'screenfull';
import { msalConfig } from 'src/app/auth-config';
import { ApiService } from 'src/app/services/api.service';
import { AppInsightService } from 'src/app/services/appInsight.service';
import { CommonService } from 'src/app/services/common.service';
import { ListingComponent } from '../listing/listing.component';
// import { User } from '../../data/user';

@Component({
  selector: 'app-userheader',
  templateUrl: './header.component.html'
})
export class userHeaderComponent implements OnInit {
  authUser = { user: { isOutStation: false, userName: 'test' } }
  count: number = 0;
  searchForm: UntypedFormGroup | any;
  advanceForm: UntypedFormGroup | any;

  private readonly _destroying$ = new Subject<void>();
  toggleSidenav = new EventEmitter<void>();
  toggleNotificationSidenav = new EventEmitter<void>();
  isOnline: any;
  loginDisplay: boolean = false;
  constructor(private router: Router, public cs: CommonService, public fb: UntypedFormBuilder,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private appInsightService: AppInsightService,
    private api: ApiService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {

    setInterval(() => {
      if (navigator.onLine) {
        this.isOnline = true;
      } else {
        this.isOnline = false;
      }
    }, 1000)

  }
  ngOnInit(): void {

    setTimeout(() => {

      if (this.cs.isloggedIn) {
        this.api.get('User/GetLoginUserDetails').subscribe(t => {
          this.cs.userName = t.userName
        },
          (err: HttpErrorResponse) => {
            this.cs.openauthpopup('You are Unauthorized', 'warning', '/');
          })
      }

    }, 3000)



    this.fetchData();

    this.cs.watchStorage().subscribe(e => {
      // let token = localStorage.getItem('token')
      // if (token != undefined && token != '' && token != null) {
      //   this.isloggedin = true
      // } else {
      //   this.isloggedin = false
      // }
      this.fetchData();
    })



    this.searchForm = this.fb.group({
      search: ['']
    })

    this.advanceForm = this.fb.group({
      title: [''],
      author: [''],
      summary: [''],
      subject: ['']
    })

  }



  advanceSearch() {
    if (this.advanceForm.dirty) {
      this.cs.advanceSearch.next(this.advanceForm.value)
    }
  }

  clearAdvanceForm() {
    this.advanceForm.reset();
    this.cs.advanceSearch.next(this.advanceForm.value)
  }

  searchFunction() {

    let search = this.searchForm.get('search') as AbstractControl
    if (search.dirty) {
      this.cs.searchValue.next(search.value)
      search.markAsPristine()
    }
  }

  eventHandler(event: any) {
    event.stopPropagation()
  }
  // msgToParent() { this.msgFromChild1.push(this.currentMsgToParent); }

  fetchData() {

    let cartData: any = localStorage.getItem('ntlCart');
    let data: any[] = JSON.parse(cartData);

    if (Array.isArray(data) && data.length) {
      this.count = data.length
    } else {
      this.count = 0
    }
  }

  fullScreenToggle(): void {
    if (screenfull.default.isEnabled) {
      screenfull.default.toggle();
    }
  }

  redirectTo(url: any) {
    this.router.navigate([url]);
  }

  home() {
    this.router.navigate(['/']);
  }

  login() {
    msalConfig.auth.redirectUri = '/'

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('userRole')
    this.authService.logoutRedirect({
      postLogoutRedirectUri: '/'
    });
  }


}
