import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ApiService } from 'src/app/services/api.service';

// export interface DialogData {
//   heading: string;
// }

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  count: number = 0
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService,) { }

  ngOnInit(): void {
    console.log(this.data);

    this.count = this.data.length
  }
}
