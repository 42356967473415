import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Observable } from 'rxjs';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-requesthistory',
  templateUrl: './requesthistory.component.html',
  styleUrls: ['./requesthistory.component.scss']
})
export class RequesthistoryComponent implements OnInit, AfterViewInit {
  PageNo: number = 1;
  length: any;
  disabledPublish: boolean = false;
  pageSize: number = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  // MatPaginator Output
  pageEvent: PageEvent | any;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  obs: Observable<any> | any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  sortModel: any;
  data: any
  email = 'pankaj.panchal@bloomcs.com'
  displayedColumns: string[] = ['requestId', 'title', 'author', 'format', 'requesteddate', 'status'];


  constructor(public cs: CommonService, private apiservice: ApiService, public changeDetectorRef: ChangeDetectorRef, public loader: LoaderService) { }

  ngOnInit(): void {
    // let params = {
    //   strEmailID: this.email
    // }
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.getRequestHistory();
  }

  getRequestHistory() {

    let params: any = {
      // NormalSearch: this.NormalSearch,
      PageNo: this.PageNo,
      PageSize: this.pageSize,
      sortColumn: 'requestDate',
      SortOrder: 'DESC'
    }
    this.apiservice.userget('UserPortal/GetAllRequestHistory', params).subscribe(
      (res: any) => {
        if (res.rowCount == 0) {
          this.disabledPublish = true;
        } else {
          this.disabledPublish = false;
        }
        this.data = res.requestHistory,
          this.length = res.rowCount,
          this.dataSource = new MatTableDataSource<any>(this.data);
      },
      (err: HttpErrorResponse) => {
        if (err.error.Message) {
          this.cs.openPopUp(err.error.Message, 'warning');
        } else if (err.error != null && err.status == 401) {
          this.cs.openauthpopup(err.error, 'warning', '/');
        }
      },
      () => {
        this.obs = this.dataSource.connect();
        setTimeout(() => {
          this.addpaginator();
          this.loader.hide()
        }, 100);
      }


    )
  }

  // setPageSizeOptions(setPageSizeOptionsInput: string) {
  // if (setPageSizeOptionsInput) {
  // this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  // }
  // }
  ngAfterViewInit(): void {
  }

  addpaginator() {
    if (this.obs && this.obs._value.length) {
      this.paginator.page.subscribe((page: any) => {
        let params: any = {
          PageNo: this.PageNo,
          PageSize: this.pageSize,
          sortColumn: 'requestDate',
          SortOrder: 'DESC'
        }
        params.PageNo = page.pageIndex + 1
        params.PageSize = page.pageSize
        this.apiservice.userget('UserPortal/GetAllRequestHistory', params)
          .subscribe((response: any) => {
            this.data = response.requestHistory
            this.dataSource = new MatTableDataSource(response.requestHistory as any[]);
            this.length = response.rowCount;
            this.obs = this.dataSource.connect();
          });
      });
    }
  }

  // Download CSV
  DownloadFile(blob:any) {
    if (blob) {
      let title = +new Date();
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = 'request_history' + title + '.xlsx';
      anchor.innerHTML='Download File';
      anchor.style.display='none';
      anchor.href = url;
      anchor.click();
    }
  }

  getExportCsv() {
    let params: any = { 
      SortColumn:'',
      SortOrder:'ASC',
   };
    this.apiservice.csvRequestHistory(params).subscribe(res=>{
      const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.DownloadFile(blob);
    }
    ,(error)=>{
      console.log("error")
    });
  }

}
