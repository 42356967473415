<mat-toolbar
  class="main-header userheaderStyle"
  style="padding: 0px 15px 0px 0px;
  font: 500 20px / 32px Roboto, Helvetica Neue, sans-serif;"
>
  <!-- color="primary" -->

  <div
    class="branding"
    (click)="home()"
    style="cursor: pointer; width: 20vw; justify-content: center"
  >
    <div class="logo"></div>
  </div>
  <!-- <button (click)="toggleSidenav.emit()" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button> -->
  <span class="width11" style="width: 11vw"></span>
  <div
    fxFlex
    class="hidewhenprint tableData"
    style="padding: 0px; margin-top: 20px; width: 30vw; justify-content: center"
  >
    <form [formGroup]="searchForm">
      <mat-form-field
        class="full-width editInput"
        style="position: relative"
        appearance="fill"
      >
        <input
          matInput
          autocomplete="off"
          (focusout)="searchFunction()"
          (keydown.enter)="searchFunction()"
          formControlName="search"
          placeholder="Search"
          value
        />
        <img
          matSuffix
          src="assets/userassets/search.png"
          width="21px"
          height="21px"
        />
      </mat-form-field>
    </form>
  </div>
  <span class="hidewhenprint advancedSearch" style="flex: 1; margin-left: 10px">
    <span
      [matMenuTriggerFor]="advancedSearch"
      style="
        cursor: pointer;
        font-size: 14px;
        align-items: center;
        display: flex;
      "
    >
      Advanced Search
    </span>

    <mat-menu
      [formGroup]="advanceForm"
      class="searchMenu"
      (click)="$event.stopPropagation()"
      #advancedSearch="matMenu"
      x-position="after"
      hidden="hidden"
    >
      <mat-form-field
        (click)="$event.stopPropagation()"
        (keydown)="eventHandler($event)"
        style="width: 100%; position: relative"
      >
        <input
          formControlName="title"
          matInput
          autocomplete="off"
          placeholder="Title"
          value
        />
      </mat-form-field>
      <mat-form-field
        (click)="$event.stopPropagation()"
        (keydown)="eventHandler($event)"
        style="width: 100%; position: relative"
      >
        <input
          formControlName="author"
          matInput
          autocomplete="off"
          placeholder="Author"
          value
        />
      </mat-form-field>
      <mat-form-field
        (click)="$event.stopPropagation()"
        (keydown)="eventHandler($event)"
        style="width: 100%; position: relative"
      >
        <input
          formControlName="subject"
          matInput
          autocomplete="off"
          placeholder="Subject"
          value
        />
      </mat-form-field>
      <mat-form-field
        (click)="$event.stopPropagation()"
        (keydown)="eventHandler($event)"
        style="width: 100%; position: relative"
      >
        <input
          matInput
          formControlName="summary"
          autocomplete="off"
          placeholder="Summary"
          value
        />
      </mat-form-field>

      <div
        style="display: flex; align-items: center; justify-content: flex-end"
      >
        <button
          mat-button
          (click)="advanceSearch()"
          class="primaryButton"
          style="
            box-shadow: 0 4px #bcbbb8;
            background: #f3b201;
            font-weight: 450;
            color: black;
          "
        >
          Submit
        </button>
        <button
          mat-button
          class="borderButton"
          (click)="clearAdvanceForm()"
          style="
            margin-left: 7px;
            box-shadow: 0px 4px #bfb8b8;
            font-weight: 450;
            color: black !important;
          "
        >
          Clear Filter
        </button>
      </div>
    </mat-menu>
  </span>

  <span class="name" style="width: 14vw; text-align: end">
    {{ cs.userName }}
  </span>
  <span style="width: 3vw" class="hidewhenprint cart flexCenter">
    <button [routerLink]="['cart']" mat-icon-button>
      <mat-icon [matBadge]="count" matBadgeColor="warn">
        add_shopping_cart
      </mat-icon>
    </button>
  </span>
  <div class="hidewhenprint menu" style="width: 5vw">
    <button
      [matMenuTriggerFor]="user"
      mat-icon-button
      class="ml-xs menuButton"
      hidden="hidden"
    >
      <!-- <mat-icon>menu</mat-icon> -->
      <img src="assets/userassets/menu.png" width="20px" height="15px" />
    </button>
  </div>
  <!-- <img src="../../../../assets/userassets/cart.png" alt=""> -->
  <mat-menu
    #user="matMenu"
    class="hidewhenprint userHeader menuOption"
    x-position="before"
    hidden="hidden"
  >
    <!-- <button routerLink="/admin" mat-menu-item>

      Login

    </button> -->
    <button routerLink="/" mat-menu-item>Browse</button>
    <button mat-menu-item>
      <a routerLink="/history">Request History</a>
    </button>
    <button *ngIf="cs.isloggedIn" (click)="logout()" mat-menu-item>
      <mat-icon>exit_to_app</mat-icon>
      Sign Out
    </button>
    <!-- <mat-icon>exit_to_app</mat-icon> -->
    <button *ngIf="!cs.isloggedIn" (click)="login()" mat-menu-item>
      Sign In
    </button>
  </mat-menu>
</mat-toolbar>
<mat-toolbar class="main-header userHeader" style="justify-content: center">
  <p style="margin: 0px">Select Titles for Library Purchase</p>
</mat-toolbar>
