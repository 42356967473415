import {
  HttpClient,
  HttpErrorResponse,
  HttpRequest,
} from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import {
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from 'src/app/app.module';
import { protectedResources } from 'src/app/auth-config';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
})
export class ListingComponent implements OnInit, AfterViewInit {
  datas: any = [
    {
      img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVOwVsMPlN6ojJlco0uCfcRQg0zwNRcmJl9Q&usqp=CAU',
      title:
        'Practical Flutter: Improve your mobile Development with Google Latest',
      by: 'Zammeti Frank',
      type: 'ebook',
      year: 2000,
      textContent: ` Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores incidunt fugiat sit
  error alias aspernatur, fugit at ratione impedit veritatis omnis! Distinctio
  deleniti maiores fugit maxime eius culpa commodi.fugit at ratione impedit.`,
    },
    {
      img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVOwVsMPlN6ojJlco0uCfcRQg0zwNRcmJl9Q&usqp=CAU',
      title:
        'Practical Flutter: Improve your mobile Development with Google Latest',
      by: 'Zammeti Frank',
      type: 'ebook',
      year: 2000,
      textContent: ` Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores incidunt fugiat sit
  error alias aspernatur, fugit at ratione impedit veritatis omnis! Distinctio
  deleniti maiores fugit maxime eius culpa commodi.fugit at ratione impedit.`,
    },
  ];
  dummyimg = 'assets/images/placeholder2.png';
  NormalSearch: string = '';
  PageNo: number = 1;
  length = 100;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  PageSize: number = 10;
  pageEvent: PageEvent | any;
  @ViewChild('tableInput', { read: ElementRef }) tableInput: ElementRef | any;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  obs: Observable<any> | any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  cartArr: any = [];
  school: any;
  BookFormat: any;
  formats: any;
  schools: any;
  searchvalue: string = '';
  advanceSearch: any;
  isloggedin = false;
  link: any
  collectionURL=this._Activatedroute.snapshot.paramMap.get("url")

  private readonly _destroying$ = new Subject<void>();
  public isMobile: boolean = false;

  constructor(
    // @Inject(DOCUMENT) private document: Document,
    public cs: CommonService,
    private apiService: ApiService,
    public loader: LoaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private http: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private _Activatedroute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    // if(this.collectionURL){
    //   console.log("collection name is : ",this.collectionURL)
    // }
    var mql = window.matchMedia('(min-width: 768px)');
    this.isMobile = mql.matches;
    mql.addListener(this.mqChange);
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;

    // const accessTokenRequest = {
    //   scopes: [environment.scope],
    //   account: account
    // }
    // let token: string | any
    // this.authService.instance.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
    //   // Acquire token silent success
    //   let accessToken = accessTokenResponse.accessToken;
    //   // Call your API with token
    //   token = accessToken

    // }).catch(function (error) {
    //Acquire token silent failure, and send an interactive request
    // if (error instanceof InteractionRequiredAuthError) {
    //     publicClientApplication.acquireTokenPopup(accessTokenRequest).then(function(accessTokenResponse) {
    //         // Acquire token interactive success
    //         let accessToken = accessTokenResponse.accessToken;
    //         // Call your API with token
    //         console.log(accessToken);
    //     }).catch(function(error) {
    //         // Acquire token interactive failure
    //         console.log(error);
    //     });
    // }
    //   console.log(error);
    // }).finally(() => {
    setTimeout(() => {
      this.fetchData();
    }, 900);
    this.loader.show();

    // }

    // )

    this.cs.searchValue.subscribe((value: any) => {
      this.searchvalue = value;
      this.fetchData();
    });

    this.cs.advanceSearch.subscribe((value: any) => {
      this.advanceSearch = value;
      this.fetchData();
    });

    // this.msalBroadcastService.inProgress$
    //   .pipe(
    //     filter((status: InteractionStatus) => status === InteractionStatus.None),
    //     takeUntil(this._destroying$)
    //   )
    //   .subscribe(() => {
    //     this.setLoginDisplay();
    //   });
  }

  mqChange = (e: any) => {
    this.isMobile = e.matches;
  }
  setLoginDisplay() {
    this.isloggedin = this.authService.instance.getAllAccounts().length > 0;
  }
  ngAfterViewInit(): void { }

  addpaginator() {
    if (this.obs && this.obs._value.length) {
      this.paginator.page.subscribe((page: any) => {
        const account = this.authService.instance.getAllAccounts()[0];

        let params: any = {
          NormalSearch: this.NormalSearch,
          PageNo: this.PageNo,
          PageSize: this.PageSize,
        };
        if(this.collectionURL != undefined){
          params['UploadType'] = this.collectionURL
        }
        if (this.formats != undefined) {
          params['BookFormat'] = this.formats;
        }
        if (this.schools != undefined) {
          params['School'] = this.schools;
        }

        if (this.cs.isloggedIn) {
          params['UserEmailId'] = account.username;
        }

        params.PageNo = page.pageIndex + 1;
        params.PageSize = page.pageSize;

        this.apiService
          .userget('UserPortal', params)
          .subscribe((response: any) => {
            this.dataSource = new MatTableDataSource(
              response.userPortalViewModel as any[]
            );
            this.length = response.rowCount;
            this.obs = this.dataSource.connect();

          });
        console.log(1);

        this.checkCart();


        this.tableInput.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      });
    }
  }

  fetchData(event?: any) {
    const account = this.authService.instance.getAllAccounts()[0]

    const params: any = {
      NormalSearch: this.NormalSearch,
      PageNo: this.PageNo,
      PageSize: this.PageSize,
    };

    if (this.cs.isloggedIn && account) {
      params['UserEmailId'] = account.username;
    }
    if (event) {
      let existformat = Object.keys(event.format).findIndex(
        (val) => event.format[val] == true
      );
      let existschool = Object.keys(event.school).findIndex(
        (val) => event.school[val] == true
      );

      if (existschool > -1) {
        this.schools = undefined;
        Object.keys(event.school).forEach((key) => {
          if (event.school[key] == true) {
            if (this.schools == undefined) {
              this.schools = key;
            } else {
              this.schools = `${this.schools},${key}`;
            }
          }
        });

        params['School'] = this.schools;
      } else {
        this.schools = undefined;
      }
      if (existformat > -1) {
        this.formats = undefined;

        Object.keys(event.format).forEach((key) => {
          if (event.format[key] == true) {
            if (this.formats == undefined) {
              this.formats = key;
            } else {
              this.formats = `${this.formats},${key}`;
            }
          }
        });
        params['BookFormat'] = this.formats;
      } else {
        this.formats = undefined;
      }
    }
    if (this.advanceSearch && this.advanceSearch.title != null) {
      params['Title'] = this.advanceSearch.title;
    } else {
      delete params['Title'];
    }
    if (this.advanceSearch && this.advanceSearch.author) {
      params['Author'] = this.advanceSearch.author;
    } else {
      delete params['Author'];
    }
    if (this.advanceSearch && this.advanceSearch.subject) {
      params['Subject'] = this.advanceSearch.subject;
    } else {
      delete params['Subject'];
    }
    if(this.collectionURL != undefined){
      params['UploadType'] = this.collectionURL
    }
    if (this.advanceSearch && this.advanceSearch.summary) {
      params['Summary'] = this.advanceSearch.summary;
    } else {
      delete params['Summary'];
    }

    params['NormalSearch'] = this.searchvalue;

    if (this.formats) {
      params['BookFormat'] = this.formats;
    }
    if (this.schools) {
      params['School'] = this.schools;
    }

    this.apiService.userget('UserPortal', params).subscribe(
      (res: any) => {
        this.datas = res.userPortalViewModel;
        this.length = res.rowCount;

        this.dataSource = new MatTableDataSource<any>(this.datas);

        // this.dataSource.data = this.datas
      },
      (error: HttpErrorResponse) => {
        this.cs.openPopUp(error.error.Message, 'warning');
      },
      () => {
        this.obs = this.dataSource.connect();
        setTimeout(() => {
          this.checkCart();
          this.addpaginator();
          this.loader.hide();
        }, 100);
      }
    );
  }

  // setPageSizeOptions(setPageSizeOptionsInput: string) {
  //   if (setPageSizeOptionsInput) {
  //     this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  //   }
  // }

  seemore(i: any) {
    if (this.obs._value[i] && this.obs._value[i]['showmore']) {
      this.obs._value[i]['showmore'] = !this.obs._value[i]['showmore'];
    } else {
      this.obs._value[i]['showmore'] = true;
    }
  }

  addlogin(id: any) {
    let user = localStorage.getItem('userRole');
    if (this.cs.isloggedIn == true) {
      if (user == null || user == '' || user == undefined) {
        this.setUserDetails(id);
      } else {
        if (user || user != '') {
          this.add(id);
        } else {
          this.cs.openauthpopup('You are Unauthorized', 'warning', '/');
        }
      }
    } else {
      this.authService.loginPopup().subscribe(e => {
        // console.log(e);
        setTimeout(() => {
          this.setUserDetails(id);
        }, 3000);


      })
    }
  }

  setUserDetails(id?: any) {
    this.apiService.get('User/GetLoginUserDetails').subscribe(
      (e) => {
        localStorage.setItem('userRole', e.userRole);
        this.cs.userName = e.userName

        if (e.userRole && e.userRole != '') {
          this.add(id);
        } else {
          this.cs.openauthpopup('You are Unauthorized', 'warning', '/');
        }
      },
      (error: HttpErrorResponse) => {
        this.cs.openauthpopup('You are Unauthorized', 'warning', '/');
      },

    );
  }

  checkCart() {
    // console.log('call check func');

    this.obs._value.forEach((obj: any) => {
      if (this.cartArr.find((e: any) => obj.id == e.id)) {
        obj['added'] = true
      }
    })
    // console.log(this.obs._value);

  }

  add(id: any) {
    let cartArr;
    // if (!this.isloggedin) {
    //   this.login()
    // } else {

    cartArr = localStorage.getItem('ntlCart');
    if (cartArr) {
      this.cartArr = JSON.parse(cartArr);
    } else {
      cartArr = [];
    }
    let product = this.obs._value.find((e: any) => e.id == id);

    if (
      this.cartArr.length &&
      this.cartArr.find((e: any) => e.id == product.id)
    ) {
      this.cs.openPopUp('Title already added to cart', 'warning');
    } else {
      this.cartArr.push(product);
    }
    this.checkCart();

    this.cs.setItem('ntlCart', JSON.stringify(this.cartArr));
    // }
  }

  checklink(link: string) {
    if (link.indexOf('http://') == 0 || link.indexOf('https://') == 0) {
      return true
    } else {
      return false
    }
  }

  convertData(value: any) {
    // console.log(value)
    // const a_tag = /<a (.*)>/g;
    // let newString = value.replace(a_tag, '<a target="_blank" $1>');

    if (value.indexOf('http://') == 0 || value.indexOf('https://') == 0) {

      this.link = value.link(value)
      const a_tag = /<a (.*)>/g;
      let newString = this.link.replace(a_tag, '<a target="_blank" $1>');
      // let link = document.createElement("a")
      // link.href = this.link
      // link.innerText = "a_tag"
      // return link
      // console.log("Return value", newString)
      return newString
    } else {
      return value
    }

    //  else {
    //   newString = newString.replace('www', 'https://www');
    //   console.log("The link doesn't have http or https.");
    // }

  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }
}
